<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text class="py-0">
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <v-text-field
                type="text"
                outlined
                dense
                label="Nombre"
                v-model="tCompNom"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" class="py-0">
              <v-text-field
                type="text"
                v-model="sigla"
                outlined
                dense
                label="Sigla"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="py-0">
              <v-switch
                class="p-0 m-0"
                v-model="permiteDistribAportes"
                label="Permitir cobro de aportes"
                item-text="value"
                item-value="id"
                dense
                outlined
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";

export default {
  name: "EditTiposComprobantesCobro",
  props: {
    tipoComprobante: {
      type: Object,
      required: false
    }
  },
  directives: { mask },
  data: () => ({
    isFormValid: false,
    formEditTitle: enums.titles.EDITAR_TIPOS_COMPROBANTES_COBRO,
    rules: rules,
    tCompNom: null,
    sigla: null
  }),
  created() {
    if (this.tipoComprobante != null) {
      this.setComprobantesCobro();
    }
    this.setTiposComprobantesCobro();
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getTiposComprobantesCobro: "aportes/getTiposComprobantesCobro",
      postTipoComprobanteCobro: "aportes/postTipoComprobanteCobro"
    }),
    closeModal() {
      this.$emit("closeAndReload");
    },
    async setTiposComprobantesCobro() {
      const comprobantesCobro = await this.getTiposComprobantesCobro();
    },
    setComprobantesCobro() {
      this.tCompNom = this.tipoComprobante.tCompNom;
      this.sigla = this.tipoComprobante.sigla;
      this.permiteDistribAportes = this.tipoComprobante.permiteDistribAportes;
    },

    async saveEdit() {
      const data = {
        tCompId:
          this.tipoComprobante != null ? this.tipoComprobante.tCompId : 0,
        permiteDistribAportes: this.permiteDistribAportes
      };
      const response = await this.postTipoComprobanteCobro(data);
      if (response.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito." });
        this.closeModal();
      }
    }
  }
};
</script>
