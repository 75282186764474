<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />
        <v-data-table
          :headers="headers"
          :items="tipoComprobanteCobro"
          class="elevation-1"
          :loading="loading"
          :search="search"
          item-key="tCompId"
        >
          <template v-slot:[`item.tCompNom`]="{ item }">
            <td>{{ item.tCompNom.toUpperCase() }}</td>
          </template>
          <template v-slot:[`item.permiteDistribAportes`]="{ item }">
            <v-icon v-if="item.permiteDistribAportes" color="primary" small>{{
              check
            }}</v-icon>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModal(item)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar cconfiguración</span>
            </v-tooltip>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-6 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="30rem"
      persistent
    >
      <EditTiposComprobantesCobro
        :tipoComprobante="tipoComprobante"
        @closeAndReload="closeAndReload"
      ></EditTiposComprobantesCobro>
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import GoBackBtn from "@/components/shared/GoBackBtn";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import Ayuda from "@/components/shared/Ayuda.vue";
import EditTiposComprobantesCobro from "@/components/modules/cuotas/aportes/EditTiposComprobantesCobro.vue";

export default {
  name: "TiposComprobantesCobro",
  components: {
    PageHeader,
    EditTiposComprobantesCobro,
    GoBackBtn,
    Ayuda
  },
  data: () => ({
    editIcon: enums.icons.EDIT,
    check: enums.icons.CHECK_OUTLINE,
    showDeleteModal: false,
    routeToGo: "ConfiguracionDDJJAportes",
    search: "",
    tipoComprobanteCobro: [],
    showExpand: false,
    showHelp: false,
    showIcon: true,
    optionCode: enums.webSiteOptions.TIPOS_COMPROBANTES_COBRO,
    searchIcon: enums.icons.SEARCH,
    title: enums.titles.TIPOS_COMPROBANTES_COBRO,
    rules: rules,
    tipoComprobante: null,
    headers: [
      {
        text: "Nombre",
        align: "start",
        value: "tCompNom",
        sortable: false
      },
      {
        text: "Sigla",
        align: "start",
        value: "sigla",
        sortable: false
      },
      {
        text: "Permite cobro de aportes",
        align: "center",
        value: "permiteDistribAportes",
        sortable: false
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    allowedActions: null,
    loading: false,
    openModalEdit: false
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadTipoComprobanteCobro();
  },
  //   mounted() {
  //     this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
  //   },
  methods: {
    ...mapActions({
      getTiposComprobantesCobro: "aportes/getTiposComprobantesCobro"
    }),
    async loadTipoComprobanteCobro() {
      this.loading = true;
      const response = await this.getTiposComprobantesCobro();
      if (response.status === 200) {
        this.tipoComprobanteCobro = response.data.data;
        this.loading = false;
      }
    },

    openModal(item) {
      this.openModalEdit = true;
      this.tipoComprobante = item;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.loadTipoComprobanteCobro();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>
